export const environment = {
    API_DOMAIN: 'office-api.archon.energy',
    API_TECHNICIANS_DOMAIN: 'technician-api.archon.energy',
    API_TIMECLOCK_DOMAIN: 'timeclock-api.archon.energy',
    API_URL: 'https://office-api.archon.energy/api',
    API_TECHNICIANS_URL: 'https://technician-api.archon.energy/api',
    API_TIMECLOCK_URL: 'https://timeclock-api.archon.energy/api',
    PUSHER_HOST: 'socket.archon.energy',
    PUSHER_PORT: 6001,
    PUSHER_AUTH_ENDPOINT: 'https://socket.archon.energy/api/broadcasting/auth',
    PUSHER_FORCE_TLS: true,
    PUSHER_APP_KEY: '003502ec5eb0a3b459a5a41e5bcbf19c',
    production: true
};