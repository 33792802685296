import {
  AirFilterAirflowRateEnum,
  AirFilterDesignedAllowableEnum,
  AirFilterDeviceTypeEnum,
  AirflowAllowanceEnum,
  AirflowMethodEnum,
  AssessmentTypeEnum,
  CeilingTypeEnum,
  CharacteristicsEnum,
  ClothesDryerFuelEnum,
  CoMonitorsInstalledEnum,
  ConditionCoolingEnum,
  ConditionEnum,
  ConstructionEnum,
  CoolingTonsEnum,
  CoolingTonsSmudEnum,
  CoolingTypeEnum,
  DuctedDuctlessEnum,
  DuctLeakageMethodEnum,
  DuctLocationEnum,
  DuctRValueEnum,
  DuctSystemComplianceCategoryEnum,
  ExemptionEnum,
  ExistingReplacedEnum,
  ExistingReplacedNAEnum,
  ExteriorFinishEnum,
  FloorInsulationEnum,
  FloorInsulationFoundationEnum,
  FrameTypeEnum,
  FrontDirectionEnum,
  FuelTypeEnum,
  GlazingTypeEnum,
  HeatingTypeEnum,
  HotWaterTypeEnum,
  IndoorsOutdoorsEnum,
  InsulationLevelEnum,
  KneeWallInsulationEnum,
  LineSizeFractionsEnum,
  LocationCazZoneEnum,
  LocationEnum,
  MakeEnum,
  NewDuctingInstalledEnum,
  NoYesOptionEnum,
  OvenTypeEnum,
  PanesEnum,
  PositionOfUnitEnum,
  RCVMethodEnum,
  RefrigerantTypesEnum,
  ReportTypeEnum,
  RoofColorEnum,
  RoofConstructionEnum,
  SpaceConditioningSystemEnum,
  SpillageEnum,
  StoveTopTypeEnum,
  SystemEnum,
  SystemsTypeEnum,
  SystemTypePackageUnitEnum,
  TestStatusListEnum,
  TypeCazZoneEnum,
  TypeCazZoneSmudEnum,
  TypeEnum,
  TypeOfMetteringDeviceEnum,
  UnitEnum,
  UnitSmudEnum,
  VisitTypeEnum,
  WallInsulationEnum,
  WallsInsulationEnum,
  WaterHeaterIndoorsLocationEnum,
  WattMeterUsedEnum,
  ZoneEnum
} from '@shared/enums';

export const ASSESSMENT_TYPE_LIST: AssessmentTypeEnum[] = [
  AssessmentTypeEnum.Initial,
  AssessmentTypeEnum.Final,
  AssessmentTypeEnum.AlternativeEemCorrected
];

export const SPACE_SYSTEM_LIST: SpaceConditioningSystemEnum[] = [
  SpaceConditioningSystemEnum.System,
  SpaceConditioningSystemEnum.Systems
];

export const FRONT_DIRECTION_LIST: FrontDirectionEnum[] = [
  FrontDirectionEnum.N,
  FrontDirectionEnum.NE,
  FrontDirectionEnum.E,
  FrontDirectionEnum.SE,
  FrontDirectionEnum.S,
  FrontDirectionEnum.SW,
  FrontDirectionEnum.W,
  FrontDirectionEnum.NW
];

export const HOT_WATER_TYPE_LIST: HotWaterTypeEnum[] = [
  HotWaterTypeEnum.ElectricStorage,
  HotWaterTypeEnum.NaturalGasStorage,
  HotWaterTypeEnum.LPGStorage,
  HotWaterTypeEnum.OilStorage,
  HotWaterTypeEnum.ElectricHeatPump,
  HotWaterTypeEnum.GasInstantaneous,
  HotWaterTypeEnum.ElectricInstantaneous,
  HotWaterTypeEnum.PropaneInstantaneous,
  HotWaterTypeEnum.OilInstantaneous
];

export const CONDITION_LIST: ConditionEnum[] = [
  ConditionEnum.Poor,
  ConditionEnum.Good,
  ConditionEnum.VeryGood,
  ConditionEnum.Excellent
];

export const CONDITION_COOLING_LIST: ConditionCoolingEnum[] = [
  ConditionCoolingEnum.NA,
  ConditionCoolingEnum.Poor,
  ConditionCoolingEnum.Good,
  ConditionCoolingEnum.VeryGood,
  ConditionCoolingEnum.Excellent
];

export const NO_YES_LIST: NoYesOptionEnum[] = [NoYesOptionEnum.No, NoYesOptionEnum.Yes];

export const ROOF_CONSTRUCTION_LIST: RoofConstructionEnum[] = [
  RoofConstructionEnum.StandardRoof,
  RoofConstructionEnum.RadiantBarrier,
  RoofConstructionEnum.RigidFoamSheathing
];

export const EXTERIOR_FINISH_LIST: ExteriorFinishEnum[] = [
  ExteriorFinishEnum.CompositionShinglesMetal,
  ExteriorFinishEnum.WoodShakes,
  ExteriorFinishEnum.ClayTile,
  ExteriorFinishEnum.ConcreteTile,
  ExteriorFinishEnum.TarGravel
];

export const INSULATION_LEVEL_LIST: InsulationLevelEnum[] = [
  InsulationLevelEnum.R0,
  InsulationLevelEnum.R11,
  InsulationLevelEnum.R13,
  InsulationLevelEnum.R15,
  InsulationLevelEnum.R19,
  InsulationLevelEnum.R21,
  InsulationLevelEnum.R27,
  InsulationLevelEnum.R30
];

export const ROOF_COLOR_LIST: RoofColorEnum[] = [
  RoofColorEnum.White,
  RoofColorEnum.Light,
  RoofColorEnum.Medium,
  RoofColorEnum.MediumDark,
  RoofColorEnum.Dark,
  RoofColorEnum.CoolColor
];

export const CEILING_TYPE_LIST: CeilingTypeEnum[] = [
  CeilingTypeEnum.UnconditionedAttic,
  CeilingTypeEnum.ConditionedAttic,
  CeilingTypeEnum.CathedralCeiling
];

export const FLOOR_INSULATION_LIST: FloorInsulationEnum[] = [
  FloorInsulationEnum.R0,
  FloorInsulationEnum.R3,
  FloorInsulationEnum.R6,
  FloorInsulationEnum.R9,
  FloorInsulationEnum.R11,
  FloorInsulationEnum.R19,
  FloorInsulationEnum.R21,
  FloorInsulationEnum.R25,
  FloorInsulationEnum.R30,
  FloorInsulationEnum.R38,
  FloorInsulationEnum.R44,
  FloorInsulationEnum.R49,
  FloorInsulationEnum.R60
];

export const TYPE_LIST: TypeEnum[] = [
  TypeEnum.SlabOnGrade,
  TypeEnum.UnconditionedBasement,
  TypeEnum.ConditionedBasement,
  TypeEnum.UnventedCrawlspace,
  TypeEnum.VentedCrawlspace
];

export const FLOOR_INSULATION_FOUNDATION_LIST: FloorInsulationFoundationEnum[] = [
  FloorInsulationFoundationEnum.R0,
  FloorInsulationFoundationEnum.R11,
  FloorInsulationFoundationEnum.R13,
  FloorInsulationFoundationEnum.R15,
  FloorInsulationFoundationEnum.R19,
  FloorInsulationFoundationEnum.R21,
  FloorInsulationFoundationEnum.R25,
  FloorInsulationFoundationEnum.R30,
  FloorInsulationFoundationEnum.R38
];

export const WALL_INSULATION_LIST: WallInsulationEnum[] = [
  WallInsulationEnum.R0,
  WallInsulationEnum.R5,
  WallInsulationEnum.R11,
  WallInsulationEnum.R19
];

export const POSITION_OF_UNIT_LIST: PositionOfUnitEnum[] = [
  PositionOfUnitEnum.Middle,
  PositionOfUnitEnum.Left,
  PositionOfUnitEnum.Right
];

export const CONSTRUCTION_LIST: ConstructionEnum[] = [
  ConstructionEnum.WoodFrame,
  ConstructionEnum.WoodFrameRigidFoamSheathing,
  ConstructionEnum.WoodFrameOptimumValueEngineering,
  ConstructionEnum.StructuralBrick,
  ConstructionEnum.ConcreteBlockStone,
  ConstructionEnum.StrawBale
];

export const WALLS_INSULATION_LIST: WallsInsulationEnum[] = [
  WallsInsulationEnum.R0,
  WallsInsulationEnum.R3,
  WallsInsulationEnum.R7,
  WallsInsulationEnum.R11,
  WallsInsulationEnum.R13,
  WallsInsulationEnum.R15,
  WallsInsulationEnum.R19,
  WallsInsulationEnum.R21,
  WallsInsulationEnum.R27,
  WallsInsulationEnum.R33,
  WallsInsulationEnum.R38
];

export const WALLS_EXTERIOR_FINISH_LIST: ExteriorFinishEnum[] = [
  ExteriorFinishEnum.WoodSiding,
  ExteriorFinishEnum.FiberCement,
  ExteriorFinishEnum.CompositeShingleMasoniteSiding,
  ExteriorFinishEnum.Stucco,
  ExteriorFinishEnum.VinylSiding,
  ExteriorFinishEnum.AluminiumSiding,
  ExteriorFinishEnum.BrickVeneer
];

export const HEATING_TYPE_LIST: HeatingTypeEnum[] = [
  HeatingTypeEnum.CentralGasFurnace,
  HeatingTypeEnum.RoomGasFurnace,
  HeatingTypeEnum.PropaneFurnace,
  HeatingTypeEnum.OilFurnace,
  HeatingTypeEnum.ElectricFurnace,
  HeatingTypeEnum.ElectricHeatPump,
  HeatingTypeEnum.ElectricBaseboardHeater,
  HeatingTypeEnum.GasBoiler,
  HeatingTypeEnum.OilBoiler,
  HeatingTypeEnum.PropaneBoiler,
  HeatingTypeEnum.GroundCoupledHeatPump,
  HeatingTypeEnum.WoodStove,
  HeatingTypeEnum.PalletStove
];

export const SYSTEM_LIST: SystemEnum[] = [SystemEnum.One, SystemEnum.Two];

export const COOLING_TYPE_LIST: CoolingTypeEnum[] = [
  CoolingTypeEnum.None,
  CoolingTypeEnum.CentralAirConditioner,
  CoolingTypeEnum.RoomAirConditioner,
  CoolingTypeEnum.ElectricHeatPump,
  CoolingTypeEnum.GroundCoupledHeatPump
];

export const LOCATION_LIST: LocationEnum[] = [
  LocationEnum.ConditionedSpace,
  LocationEnum.UnconditionedBasementUnventedCrawlspace,
  LocationEnum.VentedCrawlspace,
  LocationEnum.UnconditionedAttic
];

export const FUEL_LIST: FuelTypeEnum[] = [FuelTypeEnum.Gas, FuelTypeEnum.Electric];

export const FRAME_TYPE_LIST: FrameTypeEnum[] = [
  FrameTypeEnum.Aluminum,
  FrameTypeEnum.AluminumThermalBreak,
  FrameTypeEnum.WoodOrVinyl
];

export const GLAZING_TYPE_LIST: GlazingTypeEnum[] = [
  GlazingTypeEnum.Clear,
  GlazingTypeEnum.Tinted,
  GlazingTypeEnum.SolarControlLowE,
  GlazingTypeEnum.SolarControlLowEArgonGas,
  GlazingTypeEnum.InsulatingLowE,
  GlazingTypeEnum.InsulatingLowEArgonGas
];

export const CHARACTERISTICS_LIST: CharacteristicsEnum[] = [
  CharacteristicsEnum.All,
  CharacteristicsEnum.Front,
  CharacteristicsEnum.Back,
  CharacteristicsEnum.Left,
  CharacteristicsEnum.Right
];

export const PANES_LIST: PanesEnum[] = [
  PanesEnum.SinglePane,
  PanesEnum.DoublePane,
  PanesEnum.TriplePane
];

export const VISIT_TYPE_LIST: VisitTypeEnum[] = [VisitTypeEnum.In, VisitTypeEnum.Out];

export const CO_MONITORS_INSTALLED_LIST: CoMonitorsInstalledEnum[] = [
  CoMonitorsInstalledEnum.YesInstalledNew,
  CoMonitorsInstalledEnum.YesExisting,
  CoMonitorsInstalledEnum.Expired,
  CoMonitorsInstalledEnum.No
];

export const TEST_STATUS_LIST: TestStatusListEnum[] = [
  TestStatusListEnum.Pass,
  TestStatusListEnum.Fail
];

export const ZONE_LIST: ZoneEnum[] = [
  ZoneEnum.ZoneOne,
  ZoneEnum.ZoneTwo,
  ZoneEnum.ZoneThree,
  ZoneEnum.ZoneFour
];

export const LOCATION_CAZ_ZONE_LIST: LocationCazZoneEnum[] = [
  LocationCazZoneEnum.ConditionedSpace,
  LocationCazZoneEnum.IndoorCloset,
  LocationCazZoneEnum.OutdoorCloset,
  LocationCazZoneEnum.AttachedGarage,
  LocationCazZoneEnum.UnattachedGarage,
  LocationCazZoneEnum.Attic,
  LocationCazZoneEnum.Basement,
  LocationCazZoneEnum.Crawlspace,
  LocationCazZoneEnum.Roof,
  LocationCazZoneEnum.Outdoors,
  LocationCazZoneEnum.Others
];

export const UNIT_LIST: UnitEnum[] = [
  UnitEnum.CentralFurnace,
  UnitEnum.WaterHeater,
  UnitEnum.Boiler,
  UnitEnum.FloorFurnace,
  UnitEnum.GravityFurnace,
  UnitEnum.WallFurnaceBiv,
  UnitEnum.WallFurnaceDirectVent,
  UnitEnum.VentedRoomHeater,
  UnitEnum.UnventedRoomHeater,
  UnitEnum.Others
];

export const UNIT_SMUD_LIST: UnitSmudEnum[] = [
  UnitSmudEnum.CentralFurnace,
  UnitSmudEnum.WaterHeater,
  UnitSmudEnum.Boiler,
  UnitSmudEnum.FloorFurnace,
  UnitSmudEnum.GravityFurnace,
  UnitSmudEnum.WallFurnaceBiv,
  UnitSmudEnum.WallFurnaceDirectVent,
  UnitSmudEnum.VentedRoomHeater,
  UnitSmudEnum.UnventedRoomHeater,
  UnitSmudEnum.PrimaryHeating,
  UnitSmudEnum.SecondaryHeating,
  UnitSmudEnum.DHW,
  UnitSmudEnum.GasLogFireplace,
  UnitSmudEnum.Others
];

export const TYPE_CAZ_ZONE_LIST: TypeCazZoneEnum[] = [
  TypeCazZoneEnum.NaturalDraftColdVent,
  TypeCazZoneEnum.NaturalDraftWarmVent,
  TypeCazZoneEnum.MechanicallyAssisted,
  TypeCazZoneEnum.DirectVent
];

export const TYPE_CAZ_ZONE_SMUD_LIST: TypeCazZoneSmudEnum[] = [
  TypeCazZoneSmudEnum.NaturalDraftColdVent,
  TypeCazZoneSmudEnum.NaturalDraftWarmVent,
  TypeCazZoneSmudEnum.MechanicallyAssisted,
  TypeCazZoneSmudEnum.DirectVent,
  TypeCazZoneSmudEnum.SingleWall,
  TypeCazZoneSmudEnum.BVent,
  TypeCazZoneSmudEnum.PVSABS,
  TypeCazZoneSmudEnum.Concentric,
  TypeCazZoneSmudEnum.GasketEdStainless,
  TypeCazZoneSmudEnum.FireplaceChimney
];

export const SPILLAGE_LIST: SpillageEnum[] = [
  SpillageEnum.Pass,
  SpillageEnum.Fail,
  SpillageEnum.Na,
  SpillageEnum.NonOp
];

export const SYSTEMS_TYPE_LIST: SystemsTypeEnum[] = [
  SystemsTypeEnum.SplitUnit,
  SystemsTypeEnum.PackageUnit,
  SystemsTypeEnum.MiniSplitHeatPump
];

export const EXISTING_REPLACED_NA_LIST: ExistingReplacedNAEnum[] = [
  ExistingReplacedNAEnum.NA,
  ExistingReplacedNAEnum.Replaced,
  ExistingReplacedNAEnum.Existing
];

export const EXEMPTION_LIST: ExemptionEnum[] = [
  ExemptionEnum.NoExemption,
  ExemptionEnum.Asbestos,
  ExemptionEnum.LessThan40Feet
];

export const SYSTEM_TYPE_PACKAGE_UNIT_LIST: SystemTypePackageUnitEnum[] = [
  SystemTypePackageUnitEnum.HeatPump,
  SystemTypePackageUnitEnum.GasSystem
];

export const EXISTING_REPLACED_LIST: ExistingReplacedEnum[] = [
  ExistingReplacedEnum.Existing,
  ExistingReplacedEnum.Replaced
];

export const DUCTED_DUCTLESS_LIST: DuctedDuctlessEnum[] = [
  DuctedDuctlessEnum.Ducted,
  DuctedDuctlessEnum.Ductless
];

export const MAKE_LIST: MakeEnum[] = [
  MakeEnum.ADP,
  MakeEnum.Amana,
  MakeEnum.AmericanStandard,
  MakeEnum.Bryant,
  MakeEnum.Carrier,
  MakeEnum.Coleman,
  MakeEnum.Daikin,
  MakeEnum.DayAndNight,
  MakeEnum.Fujitsu,
  MakeEnum.Goodman,
  MakeEnum.Guardian,
  MakeEnum.Heil,
  MakeEnum.Lennox,
  MakeEnum.Mitsubishi,
  MakeEnum.Payne,
  MakeEnum.Rheem,
  MakeEnum.Ruud,
  MakeEnum.Tempstar,
  MakeEnum.Trane,
  MakeEnum.York,
  MakeEnum.Other
];

export const COOLING_TONS_LIST: CoolingTonsEnum[] = [
  CoolingTonsEnum.One,
  CoolingTonsEnum.OneAndHalf,
  CoolingTonsEnum.Two,
  CoolingTonsEnum.TwoAndHalf,
  CoolingTonsEnum.Three,
  CoolingTonsEnum.ThreeAndHalf,
  CoolingTonsEnum.Four,
  CoolingTonsEnum.Five,
  CoolingTonsEnum.Six,
  CoolingTonsEnum.Seven,
  CoolingTonsEnum.Eight,
  CoolingTonsEnum.Nine,
  CoolingTonsEnum.Ten
];

export const COOLING_TONS_SMUD_LIST: CoolingTonsSmudEnum[] = [
  CoolingTonsSmudEnum.ZeroAndHalf,
  CoolingTonsSmudEnum.One,
  CoolingTonsSmudEnum.OneAndHalf,
  CoolingTonsSmudEnum.Two,
  CoolingTonsSmudEnum.TwoAndHalf,
  CoolingTonsSmudEnum.Three,
  CoolingTonsSmudEnum.ThreeAndHalf,
  CoolingTonsSmudEnum.Four,
  CoolingTonsSmudEnum.Five,
  CoolingTonsSmudEnum.Six,
  CoolingTonsSmudEnum.Seven,
  CoolingTonsSmudEnum.Eight,
  CoolingTonsSmudEnum.Nine,
  CoolingTonsSmudEnum.Ten
];

export const AIRFLOW_METHOD_LIST: AirflowMethodEnum[] = [
  AirflowMethodEnum.FlowCaptureHood,
  AirflowMethodEnum.AirHandlerFlowMeter,
  AirflowMethodEnum.PressurePlenumMatching
];

export const AIRFLOW_ALLOWANCE_LIST: AirflowAllowanceEnum[] = [
  AirflowAllowanceEnum.CFM300,
  AirflowAllowanceEnum.CFM350
];

export const WATT_METER_USED_LIST: WattMeterUsedEnum[] = [
  WattMeterUsedEnum.PlugWattMeter,
  WattMeterUsedEnum.ClampWattMeters
];

export const RCV_METHOD_LIST: RCVMethodEnum[] = [
  RCVMethodEnum.TestedUsingGauges,
  RCVMethodEnum.WeighInMethod
];

export const REFRIGERANT_TYPES_LIST: RefrigerantTypesEnum[] = [
  RefrigerantTypesEnum.A410,
  RefrigerantTypesEnum.R22
];

export const METTERING_DEVICE_LIST: TypeOfMetteringDeviceEnum[] = [
  TypeOfMetteringDeviceEnum.TXV,
  TypeOfMetteringDeviceEnum.OrificePiston
];

export const DUCT_LEAKAGE_METHOD_LIST: DuctLeakageMethodEnum[] = [
  DuctLeakageMethodEnum.Heating,
  DuctLeakageMethodEnum.Cooling,
  DuctLeakageMethodEnum.ActualAirflow
];

export const DUCT_LOCATION_LIST: DuctLocationEnum[] = [
  DuctLocationEnum.UnconditionedAttic,
  DuctLocationEnum.UnconditionedCrawlSpace,
  DuctLocationEnum.UnconditionedBasement,
  DuctLocationEnum.UnconditionedGarage,
  DuctLocationEnum.UnconditionedSpace,
  DuctLocationEnum.ConditionedSpaceEntirely,
  DuctLocationEnum.VerifiedLowLeakageDucts,
  DuctLocationEnum.ConditionedSpaceExcept,
  DuctLocationEnum.ControlledVentilationCrawlSpace,
  DuctLocationEnum.DuctsLocatedMultiplePlaces,
  DuctLocationEnum.Outdoors
];

export const DUCT_R_VALUE_LIST: DuctRValueEnum[] = [
  DuctRValueEnum.R6,
  DuctRValueEnum.R8,
  DuctRValueEnum.R10,
  DuctRValueEnum.R12
];

export const AIR_FILTER_AIRFLOW_RATE_LIST: AirFilterAirflowRateEnum[] = [
  AirFilterAirflowRateEnum.TwoThousand
];

export const AIR_FILTER_DESIGNED_ALLOWABLE_LIST: AirFilterDesignedAllowableEnum[] = [
  AirFilterDesignedAllowableEnum.PointZeroFive,
  AirFilterDesignedAllowableEnum.PointZeroSeven,
  AirFilterDesignedAllowableEnum.PointOne
];

export const DUCT_SYSTEM_COMPLIANCE_CATEGORY_LIST: DuctSystemComplianceCategoryEnum[] = [
  DuctSystemComplianceCategoryEnum.New,
  DuctSystemComplianceCategoryEnum.Replacement,
  DuctSystemComplianceCategoryEnum.Alteration,
  DuctSystemComplianceCategoryEnum.AlterationUsingSmokeTest,
  DuctSystemComplianceCategoryEnum.ReplacementUsingSmokeTest
];

export const LINE_SIZE_LIST: LineSizeFractionsEnum[] = [
  LineSizeFractionsEnum.Half,
  LineSizeFractionsEnum.FiveEighths,
  LineSizeFractionsEnum.ThreeQuarters,
  LineSizeFractionsEnum.SevenEighths,
  LineSizeFractionsEnum.OneAndOneEighths
];

export const REPORT_TYPE_LIST: ReportTypeEnum[] = [ReportTypeEnum.BayREN, ReportTypeEnum.Smud];

export const NEW_DUCTING_INSTALLED_LIST: NewDuctingInstalledEnum[] = [
  NewDuctingInstalledEnum.ReturnOnly,
  NewDuctingInstalledEnum.SupplyOnly,
  NewDuctingInstalledEnum.BothSupplyAndReturn
];

export const AIR_FILTER_DEVICE_TYPE_LIST: AirFilterDeviceTypeEnum[] = [
  AirFilterDeviceTypeEnum.DuctMounted,
  AirFilterDeviceTypeEnum.FilterGrille,
  AirFilterDeviceTypeEnum.FurnaceMounted
];

export const INDOORS_OUTDOORS_LIST: IndoorsOutdoorsEnum[] = [
  IndoorsOutdoorsEnum.Indoors,
  IndoorsOutdoorsEnum.Outdoors
];

export const WATER_HEATER_INDOORS_LOCATION_LIST: WaterHeaterIndoorsLocationEnum[] = [
  WaterHeaterIndoorsLocationEnum.Basement,
  WaterHeaterIndoorsLocationEnum.Garage,
  WaterHeaterIndoorsLocationEnum.Attic,
  WaterHeaterIndoorsLocationEnum.LivingSpace,
  WaterHeaterIndoorsLocationEnum.InteriorCloset
];

export const STOVE_TOP_TYPE_LIST: StoveTopTypeEnum[] = [
  StoveTopTypeEnum.NaturalGas,
  StoveTopTypeEnum.ElectricFlatResistance,
  StoveTopTypeEnum.ElectricCoil,
  StoveTopTypeEnum.PropaneGas,
  StoveTopTypeEnum.ElectricFlatInduction,
  StoveTopTypeEnum.NoStoveTop
];

export const OVEN_TYPE_LIST: OvenTypeEnum[] = [
  OvenTypeEnum.NaturalGas,
  OvenTypeEnum.PropaneGas,
  OvenTypeEnum.ElectricResistance,
  OvenTypeEnum.NoStoveTop
];

export const CLOTHES_DRYER_FUEL_LIST: ClothesDryerFuelEnum[] = [
  ClothesDryerFuelEnum.NaturalGas,
  ClothesDryerFuelEnum.PropaneGas,
  ClothesDryerFuelEnum.ElectricResistance,
  ClothesDryerFuelEnum.ElectricHeatPump,
  ClothesDryerFuelEnum.NoExistingClothesDryer
];

export const KNEE_WALL_INSULATION_LIST: KneeWallInsulationEnum[] = [
  KneeWallInsulationEnum.R0,
  KneeWallInsulationEnum.R3,
  KneeWallInsulationEnum.R7,
  KneeWallInsulationEnum.R11,
  KneeWallInsulationEnum.R13,
  KneeWallInsulationEnum.R15,
  KneeWallInsulationEnum.R19,
  KneeWallInsulationEnum.R21
];
