export enum ProjectRequestSteps {
  Client = 1,
  RequestServices,
  InstallInfo,
  T24,
  HomeEnergyScore,
  RebateProgram,
  PermitRequests,
  PermitClosure,
  Scheduler,
  Payment,
  Summary
}

export enum PermitRequests {
  HVAC = 1,
  Electrical,
  Plumbing,
  Window,
  Solar,
  Roofing,
  Fencing,
  Structural,
  Siding,
  Insulation
}

export enum StepsFormType {
  Create,
  Update
}

export enum RebateOptions {
  BayRen,
  SmudHpp,
  ComfortableHomeRebates,
  Other,
  CHR,
  TECH,
  MCE,
  SonomaCleanPower,
  SGIP
}

export enum RebateOptionalOptions {
  Tech = 'Tech clean california',
  Sonoma = 'Sonoma Clean Power',
  MCE = 'MCE'
}

export enum InspectionsRequest {
  T24_Alteration,
  T24_NewConstructions,
  T24_NonResidential
}

export enum T24_Alternates {
  HomeScore,
  Rebate
}

export enum InstallationRequest {
  PackageUnit,
  SplitUnit,
  MiniSplitUnit,
  DuctReplacement,
  WallFurnace,
  Other
}

export enum TabsDetails {
  Map,
  Notes,
  Questions,
  Warnings,
  Specifics,
  Files,
  Logs,
  CommSchedule
}

export enum TabsProjectDetails {
  InstallInfo,
  T24Status,
  Permits,
  PermitClosure,
  RebatePrograms,
  HomeEnergyScore,
  Analytics,
  Documents,
  Certificates,
  Mailing
}

export enum Permit {
  HVAC,
  Electrical,
  Plumbing,
  Window,
  Solar,
  Roofing,
  Fencing,
  Structural,
  Siding,
  Insulation
}

export enum Steps {
  Client,
  RequestServices,
  InstallInfo,
  T24,
  HomeEnergyScore,
  RebateProgram,
  PermitRequests,
  PermitClosure,
  Scheduler,
  Payment,
  Summary
}

export enum StatusSectionEnum {
  Project = 'project',
  T24 = 't24',
  Inspection = 'inspection',
  Permit = 'permit',
  PermitClosure = 'permit_closure',
  Invoice = 'invoice',
  Rebate = 'rebate',
  Certificate = 'certificate',
  T24Invoice = 't24_invoice',
  DuctLeakage = 'duct_leakage',
  CazZones = 'caz_zones',
  Hes = 'hes',
  Mailing = 'mailing'
}

export enum ProjectStatuses {
  Draft = 1,
  New,
  ProcessingRequests,
  ProjectNeedsAttention,
  ProjectComplete,
  Cancel,
  RequestCancel
}

export enum InspectionStatuses {
  Processing = 1,
  InspectionDateRequested = 2,
  Scheduled = 3,
  NoShow = 4,
  InProgress = 5,
  CompletedFailed = 6,
  CompletedPassed = 9,
  CancelRequested = 10,
  DeclinedByClient = 12,
  ForcePassed = 13
}

export enum RebateStatuses {
  Processing = 1,
  InspectionDateRequested = 2,
  Scheduled = 3,
  InProgress = 5,
  CompletedFailed = 6,
  ReadyToProcessRebate = 9,
  AdditionalInfoNeeded = 10,
  RebateSubmitted = 11,
  RebateApproved = 12,
  RebateIssued = 13,
  Cancelled = 15,
  DidntQualify = 16,
  Completed = 17,
  T24Failed = 18,
  ReservationSubmitted = 21
}

export enum PermitStatuses {
  PermitRequested = 4,
  PermitScheduled = 5,
  PermitSubmittedUnderReview = 6,
  AdditionalInfoNeeded = 7,
  NeedToCollectPayment = 8,
  PermitIssued = 9,
  PermitInactiveNoResponse = 11,
  PermitAbandoned = 12,
  ServiceUnpaid = 13,
  NeedToRefund = 14,
  PermitOnHold = 15
}

export enum HesStatuses {
  ReadyToProcess = 1,
  AdditionalInformationNeeded = 2,
  ApplicationSubmitted = 3,
  RebateIssued = 4
}

export enum InvoiceServiceType {
  Permit = 'permit',
  Inspection = 'inspection'
}

export enum InvoiceType {
  System = 'system',
  Additional = 'additional'
}

export enum T24ServiceTypes {
  Alteration = 'T24_Alteration',
  NewConstructions = 'T24_NewConstructions',
  NonResidential = 'T24_NonResidential'
}

export enum InspectionAdditionalServices {
  HomeScore = 'HomeScore',
  Rebate = 'Rebate'
}

export enum MailingStatuses {
  NotMailed = 1,
  Mailed,
  Emailed,
  ReadyToMail
}

export enum CertificateStatuses {
  Incomplete = 1,
  InProgress,
  Complete,
  InProgressMissingInfo,
  ReadyToProcess
}

export enum TaskStatuses {
  Scheduled = 1,
  Failed,
  Finished
}
