export enum AssessmentTypeEnum {
  Initial = 'Initial',
  Final = 'Final',
  AlternativeEemCorrected = 'Alternative EEM Corrected'
}

export enum SpaceConditioningSystemEnum {
  System = '1 system',
  Systems = '2 systems'
}

export enum FrontDirectionEnum {
  N = 'N',
  NE = 'NE',
  E = 'E',
  SE = 'SE',
  S = 'S',
  SW = 'SW',
  W = 'W',
  NW = 'NW'
}

export enum HotWaterTypeEnum {
  ElectricStorage = 'Electric storage',
  NaturalGasStorage = 'Natural gas storage',
  LPGStorage = 'LPG storage',
  OilStorage = 'Oil storage',
  ElectricHeatPump = 'Electric heat pump',
  GasInstantaneous = 'Gas Instantaneous',
  ElectricInstantaneous = 'Electric Instantaneous',
  PropaneInstantaneous = 'Propane Instantaneous',
  OilInstantaneous = 'Oil Instantaneous'
}

export enum ConditionEnum {
  Poor = 'Poor',
  Good = 'Good',
  VeryGood = 'Very Good',
  Excellent = 'Excellent'
}

export enum ConditionCoolingEnum {
  NA = 'N/A',
  Poor = 'Poor',
  Good = 'Good',
  VeryGood = 'Very Good',
  Excellent = 'Excellent'
}

export enum NoYesOptionEnum {
  No = 'No',
  Yes = 'Yes'
}

export enum InsulationLevelEnum {
  R0 = 'R-0',
  R11 = 'R-11',
  R13 = 'R-13',
  R15 = 'R-15',
  R19 = 'R-19',
  R21 = 'R-21',
  R27 = 'R-27',
  R30 = 'R-30'
}

export enum RoofColorEnum {
  White = 'White',
  Light = 'Light',
  Medium = 'Medium',
  MediumDark = 'Medium Dark',
  Dark = 'Dark',
  CoolColor = 'Cool Color'
}

export enum RoofConstructionEnum {
  StandardRoof = 'Standard Roof',
  RadiantBarrier = 'with Radiant Barrier',
  RigidFoamSheathing = 'with Rigid Foam Sheathing'
}

export enum ExteriorFinishEnum {
  CompositionShinglesMetal = 'Composition Shingles or Metal',
  WoodShakes = 'Wood Shakes',
  ClayTile = 'Clay Tile',
  ConcreteTile = 'Concrete Tile',
  TarGravel = 'Tar & Gravel'
}

export enum CeilingTypeEnum {
  UnconditionedAttic = 'Unconditioned Attic',
  ConditionedAttic = 'Conditioned Attic',
  CathedralCeiling = 'Cathedral Ceiling'
}

export enum FloorInsulationEnum {
  R0 = 'R-0',
  R3 = 'R-3',
  R6 = 'R-6',
  R9 = 'R-9',
  R11 = 'R-11',
  R19 = 'R-19',
  R21 = 'R-21',
  R25 = 'R-25',
  R30 = 'R-30',
  R38 = 'R-38',
  R44 = 'R-44',
  R49 = 'R-49',
  R60 = 'R-60'
}

export enum TypeEnum {
  SlabOnGrade = 'Slab-on-Grade',
  UnconditionedBasement = 'Unconditioned Basement',
  ConditionedBasement = 'Conditioned Basement',
  UnventedCrawlspace = 'Unvented Crawlspace',
  VentedCrawlspace = 'Vented Crawlspace'
}

export enum FloorInsulationFoundationEnum {
  R0 = 'R-0',
  R11 = 'R-11',
  R13 = 'R-13',
  R15 = 'R-15',
  R19 = 'R-19',
  R21 = 'R-21',
  R25 = 'R-25',
  R30 = 'R-30',
  R38 = 'R-38'
}

export enum WallInsulationEnum {
  R0 = 'R-0',
  R5 = 'R-5 (slab only)',
  R11 = 'R-11 (bsmt/crawl wall)',
  R19 = 'R-19 (bsmt/crawl wall)'
}

export enum PositionOfUnitEnum {
  Middle = 'Middle',
  Left = 'Left',
  Right = 'Right'
}

export enum ConstructionEnum {
  WoodFrame = 'Wood Frame',
  WoodFrameRigidFoamSheathing = 'Wood Frame with rigid foam sheathing',
  WoodFrameOptimumValueEngineering = 'Wood Frame with Optimum Value Engineering (OVE)',
  StructuralBrick = 'Structural Brick',
  ConcreteBlockStone = 'Concrete Block or Stone',
  StrawBale = 'Straw Bale'
}

export enum WallsInsulationEnum {
  R0 = 'R-0',
  R3 = 'R-3',
  R7 = 'R-7',
  R11 = 'R-11',
  R13 = 'R-13',
  R15 = 'R-15',
  R19 = 'R-19',
  R21 = 'R-21',
  R27 = 'R-27',
  R33 = 'R-33',
  R38 = 'R-38'
}

export enum ExteriorFinishEnum {
  WoodSiding = 'Wood Siding',
  FiberCement = 'Fiber Cement',
  CompositeShingleMasoniteSiding = 'Composite Shingle or Masonite Siding',
  Stucco = 'Stucco',
  VinylSiding = 'Vinyl Siding',
  AluminiumSiding = 'Aluminium Siding',
  BrickVeneer = 'Brick Veneer'
}

export enum HeatingTypeEnum {
  CentralGasFurnace = 'Central gas furnace',
  RoomGasFurnace = 'Room (through-the-wall) gas furnace',
  PropaneFurnace = 'Propane (LPG) furnace',
  OilFurnace = 'Oil furnace',
  ElectricFurnace = 'Electric furnace',
  ElectricHeatPump = 'Electric heat pump',
  ElectricBaseboardHeater = 'Electric baseboard heater',
  GasBoiler = 'Gas Boiler',
  OilBoiler = 'Oil boiler',
  PropaneBoiler = 'Propane (LPG) boiler',
  GroundCoupledHeatPump = 'Ground coupled heat pump',
  WoodStove = 'Wood stove',
  PalletStove = 'Pallet stove'
}

export enum SystemEnum {
  One = '1',
  Two = '2'
}

export enum CoolingTypeEnum {
  None = 'None',
  CentralAirConditioner = 'Central air conditioner',
  RoomAirConditioner = 'Room air conditioner',
  ElectricHeatPump = 'Electric heat pump',
  GroundCoupledHeatPump = 'Ground coupled heat pump'
}

export enum LocationEnum {
  ConditionedSpace = 'Conditioned Space',
  UnconditionedBasementUnventedCrawlspace = 'Unconditioned basement or unvented crawlspace',
  VentedCrawlspace = 'Vented crawlspace',
  UnconditionedAttic = 'Unconditioned attic'
}

export enum FuelTypeEnum {
  Gas = 'Gas',
  Electric = 'Electric'
}

export enum ApplianceTypeEnum {
  Refrigerator = 'refrigerator',
  WashingMachine = 'washing machine',
  Dryer = 'dryer'
}

export enum CharacteristicsEnum {
  All = 'All',
  Front = 'Front',
  Back = 'Back',
  Left = 'Left',
  Right = 'Right'
}

export enum PanesEnum {
  SinglePane = 'Single-pane',
  DoublePane = 'Double-pane',
  TriplePane = 'Triple-pane'
}

export enum FrameTypeEnum {
  Aluminum = 'Aluminum',
  AluminumThermalBreak = 'Aluminum with Thermal Break',
  WoodOrVinyl = 'Wood or Vinyl'
}

export enum GlazingTypeEnum {
  Clear = 'Clear',
  Tinted = 'Tinted',
  SolarControlLowE = 'Solar-control low-E',
  SolarControlLowEArgonGas = 'Solar-control low-E, argon gas fill',
  InsulatingLowE = 'Insulating low-E',
  InsulatingLowEArgonGas = 'Insulating low-E, argon gas fill'
}

export enum SystemTypeEnum {
  SystemOne = 'systemOne',
  SystemTwo = 'systemTwo'
}

export enum VisitTypeEnum {
  In = 'in',
  Out = 'out'
}

export enum CoMonitorsInstalledEnum {
  YesInstalledNew = 'Yes (installed new)',
  YesExisting = 'Yes (existing <5 yrs.)',
  Expired = 'Expired (existing >5 yrs.)',
  No = 'No'
}

export enum ZoneEnum {
  ZoneOne = 'Zone 1',
  ZoneTwo = 'Zone 2',
  ZoneThree = 'Zone 3',
  ZoneFour = 'Zone 4'
}

export enum LocationCazZoneEnum {
  ConditionedSpace = 'Conditioned Space',
  IndoorCloset = 'Indoor Closet',
  OutdoorCloset = 'Outdoor Closet',
  AttachedGarage = 'Attached Garage',
  UnattachedGarage = 'Unattached Garage',
  Attic = 'Attic',
  Basement = 'Basement',
  Crawlspace = 'Crawlspace',
  Roof = 'Roof',
  Outdoors = 'Outdoors',
  Others = 'Others'
}

export enum UnitEnum {
  CentralFurnace = 'Central Furnace',
  WaterHeater = 'Water Heater',
  Boiler = 'Boiler',
  FloorFurnace = 'Floor Furnace',
  GravityFurnace = 'Gravity Furnace',
  WallFurnaceBiv = 'Wall Furnace (BIV)',
  WallFurnaceDirectVent = 'Wall Furnace (Direct Vent)',
  VentedRoomHeater = 'Vented Room Heater',
  UnventedRoomHeater = 'Unvented Room Heater',
  Others = 'Others'
}

export enum UnitSmudEnum {
  CentralFurnace = 'Central Furnace',
  WaterHeater = 'Water Heater',
  Boiler = 'Boiler',
  FloorFurnace = 'Floor Furnace',
  GravityFurnace = 'Gravity Furnace',
  WallFurnaceBiv = 'Wall Furnace (BIV)',
  WallFurnaceDirectVent = 'Wall Furnace (Direct Vent)',
  VentedRoomHeater = 'Vented Room Heater',
  UnventedRoomHeater = 'Unvented Room Heater',
  PrimaryHeating = 'Primary Heating',
  SecondaryHeating = 'Secondary Heating',
  DHW = 'DHW',
  GasLogFireplace = 'Gas Log Fireplace',
  Others = 'Others'
}

export enum TypeCazZoneEnum {
  NaturalDraftColdVent = 'Natural Draft (Cold Vent)',
  NaturalDraftWarmVent = 'Natural Draft (Warm Vent)',
  MechanicallyAssisted = 'Mechanically Assisted',
  DirectVent = 'Direct Vent'
}

export enum TypeCazZoneSmudEnum {
  NaturalDraftColdVent = 'Natural Draft (Cold Vent)',
  NaturalDraftWarmVent = 'Natural Draft (Warm Vent)',
  MechanicallyAssisted = 'Mechanically Assisted',
  DirectVent = 'Direct Vent',
  SingleWall = 'Single Wall',
  BVent = 'B-Vent',
  PVSABS = 'PVS/ABS',
  Concentric = 'Concentric',
  GasketEdStainless = 'Gasket Ed Stainless',
  FireplaceChimney = 'Fireplace Chimney'
}

export enum SpillageEnum {
  Pass = 'Pass',
  Fail = 'Fail',
  Na = 'NA',
  NonOp = 'Non-Op'
}

export enum TestStatusListEnum {
  Pass = 'pass',
  Fail = 'fail'
}

export enum TestStatusEnum {
  Passed = 'Passed',
  Failed = 'Failed'
}

export enum ActionLevelEnum {
  ActionLevelOne = 'Action Level #1',
  ActionLevelTwo = 'Action Level #2',
  ActionLevelThree = 'Action Level #3'
}

export enum SystemsTypeEnum {
  SplitUnit = 'SplitUnit',
  PackageUnit = 'PackageUnit',
  MiniSplitHeatPump = 'MiniSplitHeatpump'
}

export enum ExistingReplacedNAEnum {
  NA = 'n/a',
  Replaced = 'replaced',
  Existing = 'existing'
}

export enum ExemptionEnum {
  NoExemption = 'No Exemption',
  Asbestos = 'Asbestos',
  LessThan40Feet = 'Less than 40 feet'
}

export enum SystemTypePackageUnitEnum {
  HeatPump = 'Heat pump',
  GasSystem = 'Gas system'
}

export enum ExistingReplacedEnum {
  Existing = 'existing',
  Replaced = 'replaced'
}

export enum DuctedDuctlessEnum {
  Ducted = 'ducted',
  Ductless = 'ductless'
}

export enum MakeEnum {
  ADP = 'ADP',
  Amana = 'Amana',
  AmericanStandard = 'American Standard',
  Bryant = 'Bryant',
  Carrier = 'Carrier',
  Coleman = 'Coleman',
  Daikin = 'Daikin',
  DayAndNight = 'Day & Night',
  Fujitsu = 'Fujitsu',
  Goodman = 'Goodman',
  Guardian = 'Guardian',
  Heil = 'Heil',
  Lennox = 'Lennox',
  Mitsubishi = 'Mitsubishi',
  Payne = 'Payne',
  Rheem = 'Rheem',
  Ruud = 'Ruud',
  Tempstar = 'Tempstar',
  Trane = 'Trane',
  York = 'York',
  Other = 'Other'
}

export enum CoolingTonsEnum {
  One = '1.0',
  OneAndHalf = '1.5',
  Two = '2.0',
  TwoAndHalf = '2.5',
  Three = '3.0',
  ThreeAndHalf = '3.5',
  Four = '4.0',
  Five = '5.0',
  Six = '6.0',
  Seven = '7.0',
  Eight = '8.0',
  Nine = '9.0',
  Ten = '10.0'
}

export enum CoolingTonsSmudEnum {
  ZeroAndHalf = '0.5',
  One = '1.0',
  OneAndHalf = '1.5',
  Two = '2.0',
  TwoAndHalf = '2.5',
  Three = '3.0',
  ThreeAndHalf = '3.5',
  Four = '4.0',
  Five = '5.0',
  Six = '6.0',
  Seven = '7.0',
  Eight = '8.0',
  Nine = '9.0',
  Ten = '10.0'
}

export enum AirflowMethodEnum {
  FlowCaptureHood = 'flow capture hood',
  AirHandlerFlowMeter = 'air handler flow meter (flow grid)',
  PressurePlenumMatching = 'pressure plenum matching'
}

export enum AirflowAllowanceEnum {
  CFM300 = '300 CFM/ton',
  CFM350 = '350 CFM/ton'
}

export enum AirflowListOfReasonsEnum {
  LowAirflow = 'Low Airflow',
  PossibleLowFanSpeedSetting = 'Possible Low Fan Speed Setting',
  UndersizedReturn = 'Undersized Return'
}

export enum SystemsNameEnum {
  Package = 'package',
  Split = 'split',
  HeatPump = 'heatpump'
}

export enum WattMeterUsedEnum {
  PlugWattMeter = 'Plug in Watt Meter',
  ClampWattMeters = 'Clamp on Watt Meters'
}

export enum FanWattDrawListOfReasonsEnum {
  TooHigh = 'Too High',
  FanSpeedNeedAdjustment = 'Fan Speed May Need Adjustment',
  ReturnUndersized = 'Return Undersized'
}

export enum RCVMethodEnum {
  TestedUsingGauges = 'Tested using Gauges',
  WeighInMethod = 'Weigh in method'
}

export enum RefrigerantTypesEnum {
  A410 = '410-A',
  R22 = 'R-22'
}

export enum TypeOfMetteringDeviceEnum {
  TXV = 'TXV',
  OrificePiston = 'Orifice / Piston'
}

export enum RefrigerantVerificationListOfReasonsEnum {
  RefrigerantLow = 'Refrigerant Low On Charge (Low Sub-Cool)',
  RefrigerantOver = 'Refrigerant Over Charged',
  TXVIssue = 'TXV Performance Issue (High Superheat)'
}

export enum DuctLeakageMethodEnum {
  Heating = 'Heating',
  Cooling = 'Cooling',
  ActualAirflow = 'Actual Airflow'
}

export enum DuctLeakageVerificationListOfReasonsEnum {
  SupplyBoots = 'Supply Boots Not Taped',
  ReturnNotSealed = 'Return Can Not Sealed',
  DuctboardApprovedMaterials = 'Not Lined With Ductboard or Approved Materials',
  ConnectionsNotSealed = 'Connections Not Sealed',
  SupplyPlenum = 'Supply Plenum',
  ReturnPlatform = 'Return Platform',
  ReturnPlenum = 'Return Plenum',
  DisconnectedDuct = 'Disconnected Duct'
}

export enum DuctLocationEnum {
  UnconditionedAttic = 'Unconditioned attic',
  UnconditionedCrawlSpace = 'Unconditioned crawl space',
  UnconditionedBasement = 'Unconditioned basement',
  UnconditionedGarage = 'Unconditioned garage',
  UnconditionedSpace = 'Unconditioned space',
  ConditionedSpaceEntirely = 'Conditioned space - entirely',
  VerifiedLowLeakageDucts = 'Verified low-leakage ducts in conditioned space',
  ConditionedSpaceExcept = 'Conditioned space - except 12ft',
  ControlledVentilationCrawlSpace = 'Controlled ventilation crawl space',
  DuctsLocatedMultiplePlaces = 'Ducts located in multiple places',
  Outdoors = 'Outdoors'
}

export enum DuctRValueEnum {
  R6 = 'R6',
  R8 = 'R8',
  R10 = 'R10',
  R12 = 'R12'
}

export enum AirFilterAirflowRateEnum {
  TwoThousand = '2000'
}

export enum AirFilterDesignedAllowableEnum {
  PointZeroFive = '.05',
  PointZeroSeven = '.07',
  PointOne = '.1'
}

export enum DuctSystemComplianceCategoryEnum {
  New = 'New',
  Replacement = 'Replacement',
  Alteration = 'Alteration',
  AlterationUsingSmokeTest = 'Alteration using smoke test',
  ReplacementUsingSmokeTest = 'Replacement using smoke test'
}

export enum LineSizeFractionsEnum {
  Half = '1/2',
  FiveEighths = '5/8',
  ThreeQuarters = '3/4',
  SevenEighths = '7/8',
  OneAndOneEighths = '1 1/8'
}

export enum LineSizeDecimalsEnum {
  Half = '.50',
  FiveEighths = '.625',
  ThreeQuarters = '.75',
  SevenEighths = '.875',
  OneAndOneEighths = '1.125'
}

export enum ReportTypeEnum {
  BayREN = 'BayREN',
  Smud = 'Smud'
}

export enum NewDuctingInstalledEnum {
  ReturnOnly = 'Return Only',
  SupplyOnly = 'Supply Only',
  BothSupplyAndReturn = 'Both Supply and Return'
}

export enum AirFilterDeviceTypeEnum {
  DuctMounted = 'Duct Mounted',
  FilterGrille = 'Filter Grille',
  FurnaceMounted = 'Furnace Mounted'
}

export enum YesNoUnknownEnum {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown'
}

export enum IndoorsOutdoorsEnum {
  Indoors = 'Indoors',
  Outdoors = 'Outdoors'
}

export enum WaterHeaterIndoorsLocationEnum {
  Basement = 'Basement',
  Garage = 'Garage',
  Attic = 'Attic',
  LivingSpace = 'Living space',
  InteriorCloset = 'Interior closet'
}

export enum StoveTopTypeEnum {
  NaturalGas = 'Natural Gas',
  ElectricFlatResistance = 'Electric flat top (resistance)',
  ElectricCoil = 'Electric coil (resistance)',
  PropaneGas = 'Propane gas / CNG',
  ElectricFlatInduction = 'Electric flat top (induction)',
  NoStoveTop = 'No stove top'
}

export enum OvenTypeEnum {
  NaturalGas = 'Natural Gas',
  PropaneGas = 'Propane gas / CNG',
  ElectricResistance = 'Electric resistance',
  NoStoveTop = 'No oven'
}

export enum StoveTopOvenEnum {
  CombinedAppliance = 'Combined appliance (range)',
  SeparateAppliances = 'Separate appliances'
}

export enum ClothesDryerFuelEnum {
  NaturalGas = 'Natural Gas',
  PropaneGas = 'Propane gas / CNG',
  ElectricResistance = 'Electric resistance',
  ElectricHeatPump = 'Electric heat pump',
  NoExistingClothesDryer = 'No existing clothes dryer'
}

export enum ElectricalServicePanelTypeEnum {
  FuseBox = 'Fuse Box',
  CircuitBreaker = 'Circuit Breaker'
}

export enum FullElectrificationEnum {
  GreatFit = 'Great fit',
  GoodFit = 'Good fit',
  NotGoodFit = 'Not a good fit'
}

export enum CheersIframeUrlEnum {
  TestUrl = 'https://cert.cheers.org/cheers/sites?remoteprojectid=archon:',
  LiveUrl = 'https://www.cheers.org/app/sites?remoteprojectid=archon:'
}

export enum KneeWallInsulationEnum {
  R0 = 'R-0',
  R3 = 'R-3',
  R7 = 'R-7',
  R11 = 'R-11',
  R13 = 'R-13',
  R15 = 'R-15',
  R19 = 'R-19',
  R21 = 'R-21'
}
